<template>

  <v-fade-transition class="fill-height" :style="`background-color: white`" mode="out-in">

    <div v-if="loggedIn && !loading" class="fill-height">

      <v-sheet  id="business_dash_content"  :color="wsBACKGROUND" class=" fill-height  " >

        <v-row no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <v-col :style="sideBarStyle" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <avalon-admin-side-bar />
          </v-col>

          <v-col  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
            <router-view></router-view>
          </v-col>

        </v-row>

      </v-sheet>

      <avalonNewPageDialog
          v-if="$store.state.avalonPage.displayPageDialog"
      />

    </div>

    <!-- // Authorization Content -->
    <v-sheet v-else-if="!loggedIn && !loading" :color="wsBACKGROUND" class="d-flex align-center justify-center" >
      <v-sheet class=" pa-6 px-12" :class=" displayHide(['sm']) ? 'wsRoundedHalf' : '' "
               :width="displayHide(['sm']) ? 500 : '100vh'"
               :height="displayHide(['sm']) ? 700 : '100vh'">

        <h3 v-if="displayHide(['sm'])"  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h3>
        <h4 v-else  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h4>
        <authWindow light full-size no-toggle class="mt-16" />
      </v-sheet>
    </v-sheet>
    <v-sheet dark v-if="loading" :color="wsBACKGROUND" class="d-flex align-center justify-center fill-height">
      <div>
        <div class="d-flex justify-center">
          <v-progress-circular class="text-center mx-auto" size="48" indeterminate />
        </div>
        <h3 class="text-center font-weight-light">{{ $t('Loading') }}</h3>
      </div>


    </v-sheet>

  </v-fade-transition>

</template>

<script>

import avalonAdminSideBar from "@/components/AvalonEditor/ADMIN/UI/sidebar/avalonAdminSideBar";
import avalonNewPageDialog from "@/components/AvalonEditor/Dialogs/Page/avalonNewPageDialog";


import {mapState, mapActions, mapMutations} from "vuex";
import authWindow from "@/components/auth/authWindow";

export default {
  name: 'AvalonEditorSuperadmin',
  props: ['alias'],
  components: {
    avalonAdminSideBar,
    avalonNewPageDialog,
    authWindow,
  },
  data() {
    return {
      menuActive: false,
      loading : true,
    }
  },
  computed: {
    ...mapState('avalon', ['sideMenu']),
    ...mapState('auth', ['loggedIn']),

    sideBarStyle() {
      let width = this.sideMenu ? 280 : 0;
      return `min-width: ${width}px; max-width: ${width}px; height: 100% !important; transition : all 0.3s ease`
    },

    mainClass() {
      if ( this.displayShow(['sm'])) { return 'mainSmall' }
      return this.sideMenu ? 'mainWithMenu' : 'main';
    }

  },
  methods : {
    ...mapActions('avalon', ['GET_BUSINESS_AVALON']),
    ...mapMutations('auth',['LOGOUT']),

    ...mapActions('avalon', [
        'GET_ADMIN_NAVIGATION' ]
    ),

    logout() {
      this.$store.state.dashboard.showLogoutConfirm = false
      this.LOGOUT(this.$router)
      this.$router.push(this.businessPublicLink(''))
    }
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.initBusiness()
      }
    },
  },
  mounted() {

    this.$store.state.avalon.config = {
      "header" : {
        "full_width" : false,
        "width" : 1140,
        "padding_x" : 15,
        "background_color" : "background_main",
        "background_type" : "background_main"
      },
      "block" : {
        "full_width" : false,
        "vertical_padding" : true,
        "padding_top" : 75,
        "padding_bottom" : 75,
        "overlay_color" :  null,
        "overlay_opacity" : null,

        "image_position_x" :  50,
        "image_position_y" : 50,
        "image_position_sm_x" :  50,
        "image_position_sm_y" : 50,

        "width" : 1140,
        "rows" : 15,
        "rows_sm" : 15,
        "columns" : 28,
        "rowHeight" : 24,
        "gridGapVertical" : 4,
        "gridGapHorizontal" : 4,
        "background_type" : "color",
        "color" : "background_main",
        "heading_color" :  "accent",
        "text_color" : "text_dark"
      },
      "colors" : {
        "accent" : "#31081F",
        "accent2" : "#42192F",
        "background_main" : "#C0C8C3",
        "text_light" : "#E9ECEA",
        "text_dark" : "#595959",
        "medium" : "#B6B5A4"
      }
    }

    if ( !this.loggedIn) {
      this.loading = false
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  height:100% ;
}
.mainSmall {
  transition: margin-left 0.3s ease-out;
}
.main {
  margin-left: 60px !important;
  transition: margin-left 0.3s ease-out;
}
.transition {
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
}


.content {
  overflow-y: unset;
  background-color: white;
}


</style>
