<template>
  <v-row class="no-gutters flex-wrap flex-column fill-height " >

    <!-- Main items -->
    <v-col cols="auto" class="grow overflow-y-auto py-0" style="height: 10px"  >
      <v-slide-x-transition mode="out-in">
        <!-- Main Items -->
        <ws-avalon-navigation-list
            v-if="!$store.state.dashboard.displayPageNavigation"
            v-model="selectedItem"
            :items="items"
            mandatory
        />
        <div v-else>
          <router-link
              v-if="$store.state.dashboard.displayPageNavigation"
              :to="$store.state.dashboard.pageNavigationReturnRoute"
              class="noUnderline"
          >
            <v-sheet color="transparent" height="40" v-ripple style="cursor: pointer" class="d-flex align-center" >
              <v-icon :color="wsACCENT" class="ml-6 mr-4">mdi-chevron-left</v-icon>
              <h5 v-if="sideMenu || SM"  :style="`color : ${wsACCENT}`">{{  $t('Back')  }}</h5>
            </v-sheet>
          </router-link>
          <ws-avalon-navigation-list
              v-model="selectedItem"
              :items="pageNavigationItems || []"
              mandatory
          />
        </div>
        <!-- Page Navigation Items -->


      </v-slide-x-transition>

    </v-col>

    <!-- Bottom items -->
    <v-col cols="auto" class="shrink pb-5 pt-3"  >
      <v-divider class="mb-3" :style="`background-color: ${wsLIGHTCARD}`" />
      <ws-avalon-navigation-list
          v-model="selectedItem"
          :items="items_bottom"
      />
    </v-col>


  </v-row>
</template>

<script>

import {mapState} from 'vuex';
import wsAvalonNavigationList from "@/components/AvalonEditor/UI/sidebar/UI/wsAvalonNavigationList";

export default {
  name: "avalonAdminMenu",
  components : {
    wsAvalonNavigationList
  },
  data: () => ({

    selectedItem: 0,
    businessManager : false,
    parseFromRoute : '',

  }),
  watch : {
    pageNavigation(value) {
      this.selectedItem = value
    }
  },
  computed: {

    ...mapState('dashboard', [ 'pageNavigationItems']),
    ...mapState('business', ['selectedBusiness']),
    ...mapState('avalon',['sideMenu']),

    items() {

      let courses = []
      this.$store.state.avalon.coursesSelect.forEach(course => {
        courses.push(
            {
              text: course.text,
              value : course.value,
              icon: 'mdi-file',
              path: this.businessPublicLink(`editor/page/${course.page || (course.value + '/blank_course')}`),
            },
        )
      })

      let items = [
        {
          text: this.$t('Dashboard'),
          value : 'dashboard',
          icon: 'mdi-home',
          path: this.localeLink(`avalon_editor`),
        },
        {
          text: this.$t('Templates'),
          value : 'templates',
          icon: 'mdi-pencil-ruler',
          path: this.localeLink(`avalon_editor/templates`),
        },
        {
          text: this.$t('Pages'),
          value : 'pages',
          icon: 'mdi-file',
          path: this.localeLink(`avalon_editor/pages`),
        },
        {
          text: this.$t('Blocks'),
          value : 'blocks',
          icon: 'mdi-page-layout-body',
          path: this.localeLink(`avalon_editor/blocks`),

        },
        {
          text: this.$t('Headers'),
          value : 'headers',
          icon: 'mdi-page-layout-header',
          path: this.localeLink(`avalon_editor/headers`),
        },
      ]


      return items

    },
    items_bottom() {
      return [
        { text: this.$t('Logout'),
          icon: 'mdi-logout-variant',
          action: 'logout',
          value : 'logout'
        }
      ]
    }

  },
  methods: {

    select(item) {

      if ( this.SM && item.messenger ) {
        this.$store.state.messenger.displayMessenger = true
        this.$store.state.dashboard.sideMenuMobile = false
        return
      }

      switch (item.action.type) {

        case 'route':
          this.SET_PAGE_TITLE(item.text);
          this.$router.push(this.businessDashLink(item.action.path))
          break;

      }

      if ( this.SM) {
        this.$store.state.dashboard.sideMenuMobile = false
      }

    },

    //technical
    isSelected(value) {
      return this.selectedItem === value
    },
    checkRoute() {

      if ( this.$store.state.dashboard.displayPageNavigation ) {
        return
      }

    }
  },
  created() {
    let route = this.$route.path.replace(this.businessDashLink(''),'')
    this.selectedItem = route ? route : 0
    this.checkRoute()
  }
}
</script>

<style lang="scss" scoped>

::-webkit-scrollbar{height:16px;overflow:visible;width:10px}
::-webkit-scrollbar-button{height:0;width:0}
::-webkit-scrollbar-track{background-clip:padding-box;box-shadow:inset 0px 0 0 #e6e6e6}
::-webkit-scrollbar-track:hover{background-color:transparent;}
::-webkit-scrollbar-track:active{background-color:transparent; cursor: all-scroll}
::-webkit-scrollbar-thumb{background-color:#94b3c7;min-height:28px;padding:100px 0 0;}
::-webkit-scrollbar-thumb:hover{background-color:#567186;}
::-webkit-scrollbar-thumb:active{background-color:#567186;}
</style>